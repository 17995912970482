const options = [
  "asshole",
  "awesome",
  "bag",
  "because",
  "bucket",
  "bye",
  "cool",
  "cup",
  "dense",
  "dumbledore",
  "even",
  "everyone",
  "everything",
  "family",
  "fascinating",
  "flying",
  "ftfy",
  "fyyff",
  "give",
  "holygrail",
  "horse",
  "idea",
  "immensity",
  "jinglebells",
  "life",
  "logs",
  "looking",
  "lowpoly",
  "maybe",
  "me",
  "mornin",
  "no",
  "pink",
  "programmer",
  "question",
  "ratsarse",
  "retard",
  "ridiculous",
  "rtfm",
  "sake",
  "shit",
  "single",
  "thanks",
  "that",
  "this",
  "too",
  "tucker",
  "what",
  "yeah",
  "zayn",
  "zero",
];

export default options;
