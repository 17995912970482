/* gif credit: tenor.com */

import alolanvulpix from "./images/alolanvulpix.gif";
import articuno from "./images/articuno.gif";
import barboach from "./images/barboach.gif";
import bidoof from "./images/bidoof.gif";
import bidoofteeth from "./images/bidoofteeth.gif";
import braixen from "./images/braixen.gif";
import bronzonshiny from "./images/bronzonshiny.gif";
import bulbasaurgreen from "./images/bulbasaurgreen.gif";
import bulbasaurtwist from "./images/bulbasaurtwist.gif";
import castform from "./images/castform.gif";
import catleopard from "./images/catleopard.gif";
import cerneaf from "./images/cerneaf.gif";
import chansey from "./images/chansey.gif";
import charmeleon from "./images/charmeleon.gif";
import charizardxflying from "./images/charizardxflying.gif";
import charizardromp from "./images/charizardromp.gif";
import charizardscreech from "./images/charizardscreech.gif";
import charmander from "./images/charmander.gif";
import charmanderbbE from "./images/charmanderbbE.gif";
import cinderace from "./images/cinderace.gif";
import chikorita from "./images/chikorita.gif";
import crazyeyesrun from "./images/crazyeyesrun.gif";
import croagunk from "./images/croagunk.gif";
import cubone from "./images/cubone.gif";
import ditto from "./images/ditto.gif";
import djvulpix from "./images/djvulpix.gif";
import dreepy from "./images/dreepy.gif";
import eevee from "./images/eevee.gif";
import eeveecute from "./images/eeveecute.gif";
import eeveedance from "./images/eeveedance.gif";
import eeveeflareon from "./images/eeveeflareon.gif";
import eeveeglaceon from "./images/eeveeglaceon.gif";
import eeveelution from "./images/eeveelution.gif";
import eeveeperplex from "./images/eeveeperplex.gif";
import eeveerant from "./images/eeveerant.gif";
import eeveesleep from "./images/eeveesleep.gif";
import eeveetriplestep from "./images/eeveetriplestep.gif";
import eeveetwostep from "./images/eeveetwostep.gif";
import electabuzz from "./images/electabuzz.gif";
import elekid from "./images/elekid.gif";
import espeon from "./images/espeon.gif";
import espeondj from "./images/espeondj.gif";
import fennekinears from "./images/fennekinears.gif";
import fennekinsprint from "./images/fennekinsprint.gif";
import flygon from "./images/flygon.gif";
import frikoid from "./images/frikoid.gif";
import froakie from "./images/froakie.gif";
import furret from "./images/furret.gif";
import furrethappy from "./images/furrethappy.gif";
import froakielazzy from "./images/froakielazzy.gif";
import galar from "./images/galar.gif";
import garchomp from "./images/garchomp.gif";
import glaceondj from "./images/glaceondj.gif";
import glaceonspin from "./images/glaceonspin.gif";
import glaceontail from "./images/glaceontail.gif";
import glaceonyelp from "./images/glaceonyelp.gif";
import glameow from "./images/glameow.gif";
import gossifleurcolor from "./images/gossifleurcolor.gif";
import gossifleureldegoss from "./images/gossifleureldegoss.gif";
import heracross from "./images/heracross.gif";
import jolteon from "./images/jolteon.gif";
import kirlia from "./images/kirlia.gif";
import larvitar from "./images/larvitar.gif";
import larvitarsit from "./images/larvitarsit.gif";
import leafeon from "./images/leafeon.gif";
import lickitung from "./images/lickitung.gif";
import lickitunglick from "./images/lickitunglick.gif";
import litleo from "./images/litleo.gif";
import lopunnymega from "./images/lopunnymega.gif";
import lucario from "./images/lucario.gif";
import lucarioflap from "./images/lucarioflap.gif";
import lucariokick from "./images/lucariokick.gif";
import ludicolo from "./images/ludicolo.gif";
import magikarp from "./images/magikarp.gif";
import magmarfrowning from "./images/magmarfrowning.gif";
import masterball from "./images/masterball.gif";
import megalopunny from "./images/megalopunny.gif";
import mewtwo from "./images/mewtwo.gif";
import meowth from "./images/meowth.gif";
import meowthswat from "./images/meowthswat.gif";
import mew from "./images/mew.gif";
import mewspin from "./images/mewspin.gif";
import mewtwoshiny from "./images/mewtwoshiny.gif";
import miltank from "./images/miltank.gif";
import murkrow from "./images/murkrow.gif";
import natuneetoe from "./images/natuneetoe.gif";
import nintendo from "./images/nintendo.gif";
import nintendostance from "./images/nintendostance.gif";
import nonono from "./images/nonono.gif";
import octilleryswim from "./images/octilleryswim.gif";
import omanyte from "./images/omanyte.gif";
import oshawott from "./images/oshawott.gif";
import owlrowlet from "./images/owlrowlet.gif";
import panchampunch from "./images/panchampunch.gif";
import panchamshiny from "./images/panchamshiny.gif";
import pikachuhi from "./images/pikachuhi.gif";
import pikachulove from "./images/pikachulove.gif";
import pikachumusic from "./images/pikachumusic.gif";
import pikachubabe from "./images/pikachubabe.gif";
import pikachudaze from "./images/pikachudaze.gif";
import pikachupower from "./images/pikachupower.gif";
import pikachuroll from "./images/pikachuroll.gif";
import pikachuromp from "./images/pikachuromp.gif";
import pikachurun from "./images/pikachurun.gif";
import pikachutiptoe from "./images/pikachutiptoe.gif";
import pikachutriggerfingers from "./images/pikachutriggerfingers.gif";
import pikachuyeah from "./images/pikachuyeah.gif";
import poliwag from "./images/poliwag.gif";
import poochyena from "./images/poochyena.gif";
import poochyenagrayrun from "./images/poochyenagrayrun.gif";
import pumpkaboo from "./images/pumpkaboo.gif";
import raichuhelicopter from "./images/raichuhelicopter.gif";
import ralts from "./images/ralts.gif";
import regice from "./images/regice.gif";
import rockruffblush from "./images/rockruffblush.gif";
import roselia from "./images/roselia.gif";
import rotomthink from "./images/rotomthink.gif";
import ruffletbirdshiny from "./images/ruffletbirdshiny.gif";
import scolipede from "./images/scolipede.gif";
import scorbunnygalar from "./images/scorbunnygalar.gif";
import shelgon from "./images/shelgon.gif";
import shinxfrikoid from "./images/shinxfrikoid.gif";
import showkemon from "./images/showkemon.gif";
import shroomishside from "./images/shroomishside.gif";
import sirfetchshiny from "./images/sirfetchshiny.gif";
import skitty from "./images/skitty.gif";
import sneasel from "./images/sneasel.gif";
import snorlax from "./images/snorlax.gif";
import snorlaxromp from "./images/snorlaxromp.gif";
import softpokemon from "./images/softpokemon.gif";
import squirtle from "./images/squirtle.gif";
import squirtlehug from "./images/squirtlehug.gif";
import sylveon from "./images/sylveon.gif";
import togedemaru from "./images/togedemaru.gif";
import trippy from "./images/trippy.gif";
import tyranitar from "./images/tyranitar.gif";
import umbreondefense from "./images/umbreondefense.gif";
import umbreondj from "./images/umbreondj.gif";
import vapoeronspin from "./images/vapoeronspin.gif";
import venomoth from "./images/venomoth.gif";
import venusaur from "./images/venusaur.gif";
import vespiquen from "./images/vespiquen.gif";
import vulpixdoe from "./images/vulpixdoe.gif";
import vulpixfire from "./images/vulpixfire.gif";
import vulpixshine from "./images/vulpixshine.gif";
import wobbuffet from "./images/wobbuffet.gif";
import wooper from "./images/wooper.gif";
import zoroa from "./images/zoroa.gif";

const pokemonGifs = [
  alolanvulpix,
  articuno,
  barboach,
  bidoof,
  bidoofteeth,
  braixen,
  bronzonshiny,
  bulbasaurgreen,
  bulbasaurtwist,
  castform,
  catleopard,
  cerneaf,
  chansey,
  charizardxflying,
  charizardromp,
  charizardscreech,
  charmander,
  charmanderbbE,
  charmeleon,
  chikorita,
  cinderace,
  crazyeyesrun,
  croagunk,
  cubone,
  ditto,
  djvulpix,
  dreepy,
  eevee,
  eeveecute,
  eeveedance,
  eeveeflareon,
  eeveeglaceon,
  eeveelution,
  eeveeperplex,
  eeveerant,
  eeveesleep,
  eeveetriplestep,
  eeveetwostep,
  electabuzz,
  elekid,
  espeon,
  espeondj,
  fennekinears,
  fennekinsprint,
  flygon,
  frikoid,
  froakie,
  furrethappy,
  froakielazzy,
  furret,
  galar,
  garchomp,
  glaceondj,
  glaceonspin,
  glaceontail,
  glaceonyelp,
  glameow,
  gossifleurcolor,
  gossifleureldegoss,
  heracross,
  jolteon,
  kirlia,
  larvitar,
  larvitarsit,
  leafeon,
  lickitung,
  lickitunglick,
  litleo,
  lopunnymega,
  lucario,
  lucarioflap,
  lucariokick,
  ludicolo,
  magikarp,
  magmarfrowning,
  masterball,
  megalopunny,
  meowth,
  meowthswat,
  mew,
  mewspin,
  mewtwo,
  mewtwoshiny,
  miltank,
  murkrow,
  natuneetoe,
  nintendo,
  nintendostance,
  nonono,
  octilleryswim,
  omanyte,
  oshawott,
  owlrowlet,
  panchampunch,
  panchamshiny,
  pikachubabe,
  pikachudaze,
  pikachuhi,
  pikachulove,
  pikachumusic,
  pikachupower,
  pikachuroll,
  pikachuromp,
  pikachurun,
  pikachutiptoe,
  pikachutriggerfingers,
  pikachuyeah,
  poliwag,
  poochyena,
  poochyenagrayrun,
  pumpkaboo,
  raichuhelicopter,
  ralts,
  regice,
  rockruffblush,
  roselia,
  rotomthink,
  ruffletbirdshiny,
  scolipede,
  scorbunnygalar,
  shelgon,
  shinxfrikoid,
  showkemon,
  shroomishside,
  sirfetchshiny,
  skitty,
  sneasel,
  snorlax,
  snorlaxromp,
  softpokemon,
  squirtle,
  squirtlehug,
  sylveon,
  togedemaru,
  trippy,
  tyranitar,
  umbreondefense,
  umbreondj,
  vapoeronspin,
  venomoth,
  venusaur,
  vespiquen,
  vulpixdoe,
  vulpixfire,
  vulpixshine,
  wobbuffet,
  wooper,
  zoroa,
];

export default pokemonGifs;
